<template>
  <div class="assessmentDetails">
    <!-- 只有家长问卷才没有重置按钮 -->
    <el-button type="primary"
               v-show="type!==2"
               @click="refreshTeacher">重 置
    </el-button>
    <el-tooltip class="refresh-question"
                effect="dark"
                v-show="type!==2"
                content="考核教师改变请点击重置按钮"
                placement="right">
      <i class="el-icon-question" />
    </el-tooltip>
    <el-table :data="evaluateTable"
              :row-key='getRowKeys'
              :expand-row-keys="expands"
              ref="evaluateTable"
              :tree-props="{children:'children'}">
      <!-- 业务问卷 -->
      <el-table-column v-if="type===0"
                       prop="mname"
                       align="left"
                       label="被评价人">
      </el-table-column>
      <el-table-column v-if="type===0"
                       align="center"
                       label="完成状态">
        <template slot-scope="scope">
          <el-tag :type="tagColor(scope.row.status)">{{scope.row.statusstr}}</el-tag>
        </template>
      </el-table-column>
      <!-- 教师问卷 -->
      <el-table-column v-if="type===1"
                       label="被评价人"
                       align="left"
                       prop="name">
      </el-table-column>
      <el-table-column v-if="type===1"
                       prop="evaluation_name"
                       align="center"
                       label="评价人"></el-table-column>
      <el-table-column v-if="type===1"
                       prop="bookname"
                       align="center"
                       label="科组"></el-table-column>
      <el-table-column v-if="type===1"
                       prop="cname"
                       align="center"
                       label="班级"></el-table-column>
      <el-table-column v-if="type===1"
                       prop="course"
                       align="center"
                       label="学科"></el-table-column>

      <!-- 家长问卷,分管理员和用户 -->
      <el-table-column v-if="type === 2"
                       label="被评价教师"
                       align="center"
                       prop="mname"></el-table-column>
      <el-table-column v-if="type === 2 "
                       label="班级"
                       align="center"
                       prop="cname"></el-table-column>
      <el-table-column v-if="type === 2"
                       label="学科"
                       align="center"
                       prop="course"></el-table-column>
      <el-table-column label="完成状态"
                       v-if="type!==0"
                       align="center">
        <template slot-scope="scope">
          <el-tag :type="tagColor(scope.row.status)">{{scope.row.statusStr}}</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "assessmentDetails",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      currentExamineId: this.$route.query.id,
      currentExamineYearId: this.$route.query.yearid,
      evaluateTable: [],
      type: Number(this.$route.query.type), //问卷类型，0业务，1教师，2学生
      expands: [],
      getRowKeys(row) {
        return row.id;
      },
    };
  },
  created() {
    this.viewTeacher();
  },
  methods: {
    viewTeacher() {
      const that = this;
      let data = { ndid: this.currentExamineId };
      let apiUrl = "/base/queryEvaluationNdTea.do";
      if (this.type === 1) {
        data.yearid = this.currentExamineYearId;
        apiUrl = "/base/QueryQuestionTeaList.do";
      }
      if (this.type === 2) {
        data.yearid = this.currentExamineYearId;
        apiUrl = "/base/queryQuestionTeaSubject.do";
      }
      this.$post(apiUrl, data)
        .then((res) => {
          const evaluateTable = res.data;
          if (that.type === 1) {
            evaluateTable.forEach((list) => {
              list.evaluation_name = "";
              list.course = "";
              list.cname = "";
              list.bookname = "";
              list.id = "main" + list.id;
              // list.children.splice(0, 1);
              list.children.forEach((column) => {
                column.name = "";
              });
            });
          }
          that.evaluateTable = evaluateTable;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    refreshTeacher() {
      const that = this;
      let data = { ndid: currentExamineId };
      let apiUrl = "/base/insertEvaluationNdTea.do";
      if (this.type === 1) {
        data.yearid = this.currentExamineYearId;
        apiUrl = "/base/insertQuestionTea.do";
      }
      this.$post(apiUrl, data)
        .then((res) => {
          const evaluateTable = res.data;
          if (that.type === 1) {
            evaluateTable.forEach((list) => {
              list.evaluation_name = "";
              list.id = "m" + list.id;
              list.course = "";
              list.cname = "";
              list.bookname = "";
              list.children.forEach((column) => {
                column.name = "";
              });
            });
          }
          that.evaluateTable = evaluateTable;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    tagColor(status) {
      console.log(status);
      switch (status) {
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "info";
        case 3:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.refresh-question {
  margin-left: 20px;
}
</style>
